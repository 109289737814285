import Vue from 'vue';
import App from './App.vue';
import router from './router';
import SvgIcon from './components/SvgIcon/index.vue';
import VueRouter from 'vue-router';

Vue.config.productionTip = false;
Vue.component('SvgIcon', SvgIcon);

// 注册一个全局自定义指令 `v-focus`
Vue.directive('focus', {
  // 当被绑定的元素插入到 DOM 中时……
  inserted: function (el, binding) {
    if (binding.value) el.focus();
    else el.blur();
  },
  componentUpdated: function (el, binding) {
    if (binding.value) el.focus();
    else el.blur();
  },
});

Vue.directive('loading', {
  bind(el, binding) {},
  update(el, binding) {
    let oldValue = binding.oldValue;
    let value = binding.value;
    if (!oldValue && value) {
      // false -> true
      el.classList.add('svg');
      el.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <circle cx="50" cy="50" r="30" stroke="#6daac9" stroke-width="8" stroke-linecap="round" fill="none">
        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;180 50 50;720 50 50" keyTimes="0;0.5;1"></animateTransform>
        <animate attributeName="stroke-dasharray" repeatCount="indefinite" dur="1s" values="18.84955592153876 169.64600329384882;113.09733552923255 75.39822368615502;18.84955592153876 169.64600329384882" keyTimes="0;0.5;1"></animate>
      </circle>
      </svg>`;
      (el as HTMLSelectElement).disabled = true;
    } else if (!value && oldValue) {
      // true -> false
      el.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <path stroke-width="8"  stroke-dashoffset="100"  stroke-dasharray="100" stroke="#6daac9" fill="transparent" d="M28 52l14 14 28-28">
            <animate attributeName="stroke-dashoffset" attributeType="XML" to="30" begin=".1s" fill="freeze" dur=".3s" />
      </path>
    </svg>`;
      setTimeout(() => {
        el.classList.remove('svg');
        el.innerHTML = '记录';
        (el as HTMLSelectElement).disabled = false;
      }, 1000);
    }
  },
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
