import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";

Vue.use(VueRouter);

/**
 * Array<RouteConfig>
 */
const routes = [
  {
    path: "*",
    name: "Home",
    component: () => import("../views/Memo/index.vue"),
  },
  // {
  //   path: "/login",
  //   name: "Login",
  //   meta: {
  //     title: "注册或登录Kwok.INK",
  //   },
  //   component: () => import("../views/Login.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const whiteList = ["/login"];

router.beforeEach(async (to, from, next) => {
  const toPath = to.path;
  if (to && to.meta && to.meta.title) {
    document.title = `${to.meta.title}`;
  } else {
    document.title = "Memos";
  }
  next();
  // if (whiteList.includes(to.path) || repo.currentUser) {
  //   next();
  // } else if (Token.get()) {
  //   const user = await Login.info();
  //   next();
  // } else {
  //   next(`/login?redirect=${to.path}`);
  // }
});

export default router;
